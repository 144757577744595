import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import Category from "./components/pages/Category";
import VendorMgt from "./components/pages/VendorMgt";
import UserMgt from "./components/pages/UserMgt";
import EditUser from "./components/pages/UserMgt/editUser";
import EditVendor from "./components/pages/VendorMgt/editVendor";
import Transaction from "./components/pages/Transaction/index";
import ViewTransaction from "./components/pages/Transaction/ViewTransaction";
import Publications from "./components/pages/Publications";
import Payout from "./components/pages/Payout";
import Dispute from "./components/pages/Dispute";
import Messages from "./components/pages/Message";
import Subscription from "./components/pages/Subscription";
import CreateCategory from "./components/pages/Category/Create";
import EditCategory from "./components/pages/Category/Edit";
import CreatePublications from "./components/pages/Publications/Create";
import EditPublications from "./components/pages/Publications/Edit";
import CreateVendor from "./components/pages/VendorMgt/Create";
import CreateAdmin from "./components/pages/adminMgt/Create";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ForgotPassword/ResetPassword";
import Logout from "./components/pages/Logout";
import Verify from "./components/pages/Verify";
import Reports from "./components/pages/Report/index";
import PublicNotice from "./components/pages/PublicNotice";
import PublicNoticeApplications from "./components/pages/PublicNotice/Applications";
import Con from "./components/pages/ChangeOfName";
import Applications from "./components/pages/ChangeOfName/Applications";
import Archives from "./components/pages/Archives";
import Account from "./components/pages/Account";
import ArchiveApplications from "./components/pages/Archives/Applications";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import ViewVendor from "./components/pages/vendorDetails/vendorProfile";
import "./App.css";
import AdminMgt from "./components/pages/adminMgt/index";
import { UserDataProvider } from "./context/user.jsx";
import UserSettings from "./components/pages/userProfile/user";
import Messaging from "./components/messanging/index";
import CouponMgt from "./components/pages/CouponMgt/index.jsx";

function App() {
  return (
    <>
      <NotificationContainer />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route path="/reset_password/:token" component={ResetPassword} />
          <Route path="/verify" component={Verify} />
          <Route path="/logout" component={Logout} />
          <UserDataProvider>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/categories" component={Category} />
            <Route exact path="/subscriptions" component={Subscription} />
            <Route exact path="/create_category" component={CreateCategory} />
            <Route exact path="/edit_category/:id" component={EditCategory} />
            <Route
              exact
              path="/create_publication"
              component={CreatePublications}
            />
            <Route
              exact
              path="/edit_publications/:id"
              component={EditPublications}
            />
            <Route exact path="/create_admin" component={CreateAdmin} />
            <Route exact path="/vendor_management" component={VendorMgt} />
            <Route exact path="/create_vendor" component={CreateVendor} />
            <Route exact path="/user_management" component={UserMgt} />
            <Route exact path="/edit_user/:id" component={EditUser} />
            <Route exact path="/edit_vendor/:id" component={EditVendor} />
            <Route exact path="/view_vendor/:id" component={ViewVendor} />
            <Route exact path="/publications" component={Publications} />
            <Route exact path="/transactions" component={Transaction} />
            <Route
              exact
              path="/view_transaction/:id"
              component={ViewTransaction}
            />
            <Route exact path="/admin_mgt" component={AdminMgt} />
            <Route exact path="/payouts" component={Payout} />
            <Route exact path="/disputes" component={Dispute} />
            <Route exact path="/messages" component={Messages} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/settings" component={UserSettings} />
            <Route exact path="/con_vendors" component={Con} />
            <Route exact path="/pn_vendors" component={PublicNotice} />
            <Route exact path="/archive_vendors" component={Archives} />
            <Route exact path="/account" component={Account} />
            <Route
              exact
              path="/con_vendors/applications"
              component={Applications}
            />
            <Route
              exact
              path="/pn_vendors/applications"
              component={PublicNoticeApplications}
            />
            <Route
              exact
              path="/archive_vendors/applications"
              component={ArchiveApplications}
            />
            <Route exact path="/messaging" component={Messaging} />
            <Route exact path="/coupon_mgt" component={CouponMgt} />
          </UserDataProvider>
        </Switch>
      </Router>
    </>
  );
}

export default App;
