import React, { useState } from "react";
import Table from "../../shared/CustomTable";
import ViewCouponModal from "./ViewCoupon";

function CouponTable({ coupons, expireCoupon }) {
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState(null);

  const onClose = () => setOpen(false);

  const onView = (data) => {
    setCoupon(data);
    setOpen(true);
  };

  const bodyRow = () => {
    const body = coupons.map((data, index) => ({
      couponCodes: data.code,
      amount: data.amount,
      createDate: new Date(data.createdAt).toDateString(),
      status: (
        <div
          className={`status ${
            data.used ? "redeemed" : data.expired ? "expired" : "active"
          }`}
        >
          {data.used ? "Redeemed" : data.expired ? "Expired" : "Active"}
        </div>
      ),
      action: (
        <button className="view-coupon-button" onClick={() => onView(data)}>
          View
        </button>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Coupon Codes",
        prop: "couponCodes",
        sortable: true,
        filterable: true,
      },

      { title: "Amount", prop: "amount", sortable: true, filterable: true },

      { title: "Create Date", prop: "createDate" },

      {
        title: "Status",
        prop: "status",
        sortable: true,
        filterable: true,
      },
      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
      <ViewCouponModal
        open={open}
        onClose={onClose}
        coupon={coupon}
        expireCoupon={expireCoupon}
      />
    </div>
  );
}

export default CouponTable;
