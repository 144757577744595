import React, { useState, useEffect, useContext } from "react";
import "./layout.css";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import avatar from "../../../assets/image/avatar.png";
import { UserDataContext } from "./../../../context/user";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";

export default function Sidebar(props) {
  const { UserInfo } = useContext(UserDataContext);
  const [role, setRole] = useState("");
  const [conDrop, setConDrop] = useState(false);
  const [arcDrop, setArcDrop] = useState(false);
  const [pnDrop, setPnDrop] = useState(false);
  const [toggleSidebarIn, settoggleSidebarIn] = useState({
    dropDownEvent: false,
    dropDownGroup: false,
    dropDownPosts: false,
    dropDownTopics: false,
    dropDownUsers: false,
    dropDownBoard: false,
    userInfo: null,
  });
  const [UserInfoIn, setUserInfoIn] = useState("");

  const getUser = async () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    setRole(parseUserData?.role?.name);
    console.log(setUserInfoIn);
    let d = await UserInfo;
    console.log("user from>>>>", d);
    setUserInfoIn(d);
  };

  const dropDown = (events) => {
    let dropDownEvent = toggleSidebarIn.dropDownEvent;
    let dropDownGroup = toggleSidebarIn.dropDownGroup;
    if (events === "events") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownEvent: !toggleSidebarIn.dropDownEvent,
        dropDownGroup: false,
        dropDownPosts: false,
        dropDownTopics: false,
        dropDownUsers: false,
      });
    }

    if (events === "groups") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownGroup: !toggleSidebarIn.dropDownGroup,
        dropDownEvent: false,
        dropDownPosts: false,
        dropDownTopics: false,
        dropDownUsers: false,
      });
    }

    if (events === "posts") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownPosts: !toggleSidebarIn.dropDownPosts,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownTopics: false,
        dropDownUsers: false,
      });
    }

    if (events === "topics") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownTopics: !toggleSidebarIn.dropDownTopics,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownUsers: false,
      });
    }

    if (events === "users") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownUsers: !toggleSidebarIn.dropDownUsers,
      });
    }

    if (events === "board") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownUsers: false,
        dropDownBoard: !toggleSidebarIn.dropDownBoard,
      });
    }
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.grey,
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }))(Tooltip);

  useEffect(() => {
    getUser();
  }, [UserInfo, getUser]);
  return (
    <div>
      <div
        className={`${
          props.sidebarShow === "showSidebar" ? "showSidebar" : "hideSidebar"
        } ${props.SidebarDefault}`}
      >
        <div className={`sidebar`}>
          <Scrollbars
            style={{ width: 258, height: "100vh " }}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            <ul className="sidebarList">
              <div className="userDetailsSidebar">
                <div className="userDImageSideWrap">
                  <img
                    src={
                      UserInfoIn === null
                        ? avatar
                        : UserInfoIn?.avatar === null
                        ? avatar
                        : UserInfoIn?.avatar
                    }
                    alt=""
                  />
                </div>
                <div className="usernameWrap">
                  {`${
                    UserInfoIn === null ? "loading..." : UserInfoIn?.firstName
                  } ${
                    UserInfoIn === null ? "loading..." : UserInfoIn?.lastName
                  }`}
                </div>
                <div style={{ marginLeft: "-68px" }} className="useremail my-1">
                  {`${UserInfoIn === null ? "loading..." : UserInfoIn?.email}`}
                </div>
              </div>

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/dashboard");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "dashboard" ? "activeClass" : ""}`}
              >
                <i className="fas fa-th"></i>Dashboard
              </li>
              {role == "analyst admin" || role == "finance admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={
                      role === "finance admin" || role === "analyst admin"
                        ? ""
                        : () => props.RouteUserLayout.push("/user_management")
                    }
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}
              {role == "analyst admin" || role == "finance admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "user_mgt" ? "activeClass" : ""
                  }`}
                >
                  <i className="fa fa-user"></i> User Management
                </li>
              )}

              {role == "analyst admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={role === "analyst admin" ? "unauthorized" : ""}
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={
                      role === "analyst admin"
                        ? ""
                        : () => props.RouteUserLayout.push("/vendor_management")
                    }
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}
              {role == "analyst admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "vendor_mgt" ? "activeClass" : ""
                  }`}
                >
                  <i className="fas fa-users"></i> Vendor Management
                </li>
              )}

              {role == "analyst admin" || role == "finance admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={
                      role === "finance admin" || role === "analyst admin"
                        ? ""
                        : () => {
                            props.RouteUserLayout.push("/admin_mgt");
                          }
                    }
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}
              {role == "analyst admin" || role == "finance admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "admin_mgt" ? "activeClass" : ""
                  }`}
                >
                  <i className="far fa-user"></i>Admin Management
                </li>
              )}
              
              {role == "analyst admin" || role == "finance admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={
                      role === "finance admin" || role === "analyst admin"
                        ? ""
                        : () => {
                            props.RouteUserLayout.push("/coupon_mgt");
                          }
                    }
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}
              {role == "analyst admin" || role == "finance admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "coupon_mgt" ? "activeClass" : ""
                  }`}
                >
                  <i className="fas fa-credit-card"></i>Generate Coupon Code
                </li>
              )}

              <div
                onClick={() => props.RouteUserLayout.push("/subscriptions")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "subscription" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-wallet"></i> Subscription
              </li>
              {role == "analyst admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={
                      role === "finance admin" || role === "analyst admin"
                        ? ""
                        : () => props.RouteUserLayout.push("/categories")
                    }
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}
              {role == "analyst admin" || role === "finance admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "categories" ? "activeClass" : ""
                  }`}
                >
                  <i className="fas fa-vote-yea"></i>Categories
                </li>
              )}

              {role == "analyst admin" || role === "finance admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={
                      role === "finance admin" || role === "analyst admin"
                        ? ""
                        : () => {
                            props.RouteUserLayout.push("/publications");
                          }
                    }
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}
              {role == "analyst admin" || role === "finance admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "products" ? "activeClass" : ""
                  }`}
                >
                  <i className="fas fa-shopping-bag"></i> Publication Types
                </li>
              )}
              <div
                onClick={() => {
                  props.RouteUserLayout.push("/transactions");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "transactions" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-credit-card"></i>Transactions
              </li>

              <LightTooltip
                title={
                  role === "finance admin" || role === "analyst admin"
                    ? "unauthorized"
                    : ""
                }
                arrow
                placement="right-start"
              >
                <div
                  onClick={() => {
                    // props.RouteUserLayout.push("/con_vendors");
                    setConDrop(true);
                  }}
                  className="hoverActiveRoute"
                ></div>
              </LightTooltip>

              <div>
                <li
                  className={`${
                    props.page === "con_vendors" ||
                    props.page === "applications"
                      ? "activeClass"
                      : ""
                  } `}
                >
                  {/* <i className="fas fa-edit"></i> CON Subscriptions */}
                  <i className="fas fa-edit"></i>{" "}
                  <span className="mr-3">Change of name </span>
                  <i
                    className={
                      !conDrop ? `fa fa-angle-right` : `fa fa-angle-down`
                    }
                  ></i>
                </li>

                {conDrop ? (
                  <li
                    className=""
                    style={{
                      position: "relative",
                      borderRadius: "4px",
                      background: "#fff",
                      color: " #E47E15",
                      width: "100%",
                      top: "-13px",
                    }}
                  >
                    <div
                      className="px-2 py-2"
                      onClick={() => {
                        setConDrop(false);

                        props.RouteUserLayout.push("/con_vendors");
                      }}
                    >
                      Subscriptions
                    </div>
                    <hr className="p-0 m-0" />
                    <div
                      className="px-2 py-2"
                      onClick={() => {
                        setConDrop(false);
                        props.RouteUserLayout.push("/con_vendors/applications");
                      }}
                    >
                      Applications
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </div>

              <LightTooltip
                title={
                  role === "finance admin" || role === "analyst admin"
                    ? "unauthorized"
                    : ""
                }
                arrow
                placement="right-start"
              >
                <div
                  onClick={() => {
                    // props.RouteUserLayout.push("/con_vendors");
                    setArcDrop(true);
                  }}
                  className="hoverActiveRoute"
                ></div>
              </LightTooltip>

              <div>
                <li
                  className={`${
                    props.page === "archive_vendors" ||
                    props.page === "arcApplications"
                      ? "activeClass"
                      : ""
                  }`}
                >
                  {/* <i className="fas fa-edit"></i> CON Subscriptions */}
                  <i className="fas fa-archive"></i>{" "}
                  <span className="mr-3">Archives</span>
                  <i
                    className={
                      !arcDrop ? `fa fa-angle-right` : `fa fa-angle-down`
                    }
                  ></i>
                </li>

                {arcDrop ? (
                  <li
                    className=""
                    style={{
                      position: "relative",
                      borderRadius: "4px",
                      background: "#fff",
                      color: " #E47E15",
                      width: "100%",
                      top: "-13px",
                    }}
                  >
                    <div
                      className="px-2 py-2"
                      onClick={() => {
                        setArcDrop(false);
                        props.RouteUserLayout.push("/archive_vendors");
                      }}
                    >
                      Subscriptions
                    </div>
                    <hr className="p-0 m-0" />
                    <div
                      className="px-2 py-2"
                      onClick={() => {
                        setArcDrop(false);
                        props.RouteUserLayout.push(
                          "/archive_vendors/applications"
                        );
                      }}
                    >
                      Applications
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </div>

              <LightTooltip
                title={
                  role === "finance admin" || role === "analyst admin"
                    ? "unauthorized"
                    : ""
                }
                arrow
                placement="right-start"
              >
                <div
                  onClick={() => {
                    // props.RouteUserLayout.push("/con_vendors");
                    setPnDrop(true);
                  }}
                  className="hoverActiveRoute"
                ></div>
              </LightTooltip>

              <div>
                <li
                  className={`${
                    props.page === "public_notice" ||
                    props.page === "pnApplications"
                      ? "activeClass"
                      : ""
                  }`}
                >
                  {/* <i className="fas fa-edit"></i> CON Subscriptions */}
                  <i className="fas fa-bullhorn"></i>
                  <span className="mr-3">Public Notice</span>
                  <i
                    className={
                      !pnDrop ? `fa fa-angle-right` : `fa fa-angle-down`
                    }
                  ></i>
                </li>

                {pnDrop ? (
                  <li
                    className=""
                    style={{
                      position: "relative",
                      borderRadius: "4px",
                      background: "#fff",
                      color: " #E47E15",
                      width: "100%",
                      top: "-13px",
                    }}
                  >
                    <div
                      className="px-2 py-2"
                      onClick={() => {
                        setPnDrop(false);
                        props.RouteUserLayout.push("/pn_vendors");
                      }}
                    >
                      Subscriptions
                    </div>
                    <hr className="p-0 m-0" />
                    <div
                      className="px-2 py-2"
                      onClick={() => {
                        setPnDrop(false);
                        props.RouteUserLayout.push("/pn_vendors/applications");
                      }}
                    >
                      Applications
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </div>

              {role == "analyst admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={() => {
                      props.RouteUserLayout.push("/account");
                    }}
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}

              {role == "analyst admin" ? (
                ""
              ) : (
                <div>
                  <li
                    className={`${
                      props.page === "account" ? "activeClass" : ""
                    }`}
                  >
                    {/* <i className="fas fa-edit"></i> CON Subscriptions */}
                    <i className="fas fa-flag"></i> Account
                  </li>
                </div>
              )}

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/reports");
                }}
                className="hoverActiveRoute"
              ></div>

              <li
                className={`${props.page === "reports" ? "activeClass" : ""}`}
              >
                <i className="far fa-comments"></i>Reports
              </li>

              {/* {role == "analyst admin" ? (
                ""
              ) : (
                <LightTooltip
                  title={
                    role === "finance admin" || role === "analyst admin"
                      ? "unauthorized"
                      : ""
                  }
                  arrow
                  placement="right-start"
                >
                  <div
                    onClick={() => {
                      props.RouteUserLayout.push("/con_vendors/applications");
                    }}
                    className="hoverActiveRoute"
                  ></div>
                </LightTooltip>
              )}

              {role == "analyst admin" ? (
                ""
              ) : (
                <li
                  className={`${
                    props.page === "applications" ? "activeClass" : ""
                  }`}
                >
                  <i class="fas fa-file-invoice"></i> CON Applications
                </li>
              )} */}

              <div
                onClick={
                  // role === "finance admin" || role === "analyst admin"
                  //   ? ""
                  //   :
                  () => {
                    props.RouteUserLayout.push("/messaging");
                  }
                }
                className="hoverActiveRoute"
              ></div>

              <li
                className={`${props.page === "messaging" ? "activeClass" : ""}`}
              >
                <i className="far fa-comments"></i> Messages
              </li>
              <div
                onClick={() => {
                  props.RouteUserLayout.push("/settings");
                }}
                className="hoverActiveRoute"
              ></div>

              <li
                className={`${props.page === "settings" ? "activeClass" : ""}`}
              >
                <i className="fas fa-cogs"></i> Settings
              </li>

              <div className="mt-5">
                <div
                  onClick={() => props.RouteUserLayout.push("/logout")}
                  className="hoverActiveRoute"
                ></div>
                <li className="d-flex justify-content-center">
                  <i className="fas fa-sign-out-alt"></i>Signout
                </li>
              </div>
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}
