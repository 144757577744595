import React, { useState, useEffect, useContext } from 'react';
import { UserDataContext } from '../../../context/user';
import { httpGet, httpPatch, httpPost } from '../../../action/http';
import FormatDate from '../../../helpers/formatDate';
import Layout from '../../shared/Layout';
import './style.css';
import UpdateAccount from './UpdateAccount';
import AddAccount from './AddAccount';
import WithdrawMoney from './WithdrawMoney';
import AccImg from '../../../assets/Account.svg';
import AccImg2 from '../../../assets/AccImg2.svg';
import AccImg3 from '../../../assets/AccImg3.svg';
import Settings from '../../../assets/settings.svg';
import Table from '../../shared/DataTable';
import { hideLoader, showLoader } from '../../../utils/loader';
import swal from 'sweetalert';

const Index = ({ history }) => {
  const {
    TransactionReport,
    TransactionReport: {
      payout,
      tnsProfit,
      subscriptionPayments,
      totalUserCurrentWalletBalance,
      totalVendorCurrentWalletBalance,
      fundWalletTransactions,
      withdrawalAmount,
    },
  } = useContext(UserDataContext);
  const [showModal, hideModal] = useState(false);
  const [data, setData] = useState({});
  const [showAddAccountModal, hideAddAccountModal] = useState(false);
  const [withdrawModal, hideWithdrawModal] = useState(false);
  const [withdrawals, setWithdrawals] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    getHistory();
    getBankDetails();
    getBankLists();
  }, []);

  console.log('banks>>>', banks);

  const getHistory = async () => {
    showLoader();
    const res = await httpGet('/account/history');
    if (res.code === 200) {
      setWithdrawals(res.data);
      hideLoader();
    } else {
      hideLoader();
      return;
    }
  };

  const getBankDetails = async () => {
    showLoader();
    const res = await httpGet('/account/active');
    if (res.code === 200) {
      setBankDetails(res?.data?.adminAccount);
      hideLoader();
    } else {
      hideLoader();
      return;
    }
  };

  const getBankLists = async () => {
    const res = await httpGet('/banks');
    if (res.code === 200) {
      setBanks(res?.data?.bankList);
    } else {
      return;
    }
  };

  const handleAddAccount = async () => {
    if (data?.accountNumber === '') {
      swal('error', 'Add Account Number', 'error');
      return;
    }
    if (data?.accountName === '') {
      swal('error', 'Add Account Name', 'error');
      return;
    }
    if (data?.bankName === '') {
      swal('error', 'please select Bank', 'error');
      return;
    }
    showLoader();
    const postData = {
      ...data,
    };
    const res = await httpPost('/account/create', postData);
    if (res.code === 200) {
      hideAddAccountModal(false);
      getBankDetails();
      hideLoader();
      setData({});
    } else {
      swal('error', res.message, 'error');
      hideLoader();
      return;
    }
  };

  const handleWithdrawal = async () => {
    if (data?.amount === '') {
      swal('error', 'Add Amount', 'error');
      return;
    }
    if (data?.currency === '') {
      swal('error', 'Select currency', 'error');
      return;
    }

    showLoader();
    const postData = {
      ...data,
    };
    const res = await httpPost('/account/withdraw', postData);
    if (res.code === 200) {
      hideWithdrawModal(false);
      getBankDetails();
      hideLoader();
      setData({});
      getHistory();
      swal('success', res.message, 'success');
    } else {
      swal('error', res.message, 'error');
      hideLoader();
      return;
    }
  };

  const handleEditAccount = async () => {
    if (!data?.accountNumber) {
      swal('error', 'Add Account Number', 'error');
      return;
    }
    if (!data?.accountName) {
      swal('error', 'Add Account Name', 'error');
      return;
    }
    if (!data?.bankName) {
      swal('error', 'please select Bank', 'error');
      return;
    }
    showLoader();
    const postData = {
      ...data,
    };
    const res = await httpPatch(`/account/update/${bankDetails?.id}`, postData);
    if (res.code === 200) {
      hideModal(false);
      getBankDetails();
      hideLoader();
      setData({});
    } else {
      swal('error', res.message, 'error');
      hideLoader();
      return;
    }
  };

  const getBankAccountDetails = async (accountNumber, bankCode) => {
    showLoader();
    const res = await httpGet(
      `/bank/resolve?accountNumber=${accountNumber}&bankCode=${bankCode}`
    );
    if (res.success) {
      setData({
        ...data,
        accountName: res.data?.accountDetails?.accountName,
        accountNumber,
      });
      hideLoader();
    } else {
      hideLoader();
      swal('error', res?.message, 'error');
      return;
    }
  };

  console.log('report>>>', TransactionReport);

  return (
    <div>
      <Layout
        RouteUserLayout={history}
        page='account'
        activepage='keepOpenGroup'
      >
        <div id='Account'>
          <div
            style={{
              marginBottom: '70px',
            }}
            className='account-head'
          >
            <div
              className='account-box'
              style={{
                background: `rgba(29, 185, 49, 0.4) url(${AccImg}) no-repeat no-repeat 90% 80%`,
              }}
            >
              <div className='account-box-top'>
                <div>Total</div>
                <div>TNS Balance</div>
              </div>
              <div className='account-box-bot'>
                {TransactionReport
                  ? Number(tnsProfit) - Number(withdrawalAmount)
                  : 0}
              </div>
            </div>
            <div
              className='account-box'
              style={{
                background: `rgba(255, 182, 0, 0.4) url(${AccImg2}) no-repeat no-repeat 90% 80%`,
              }}
            >
              <div className='account-box-top'>
                <div>Total</div>
                <div>TNS Withdrawal</div>
              </div>

              <div className='account-box-bot'>{withdrawalAmount}</div>
            </div>
            <div
              className='account-box'
              style={{
                background: `#EDF2F7 url(${AccImg3}) no-repeat no-repeat 90% 80%`,
              }}
            >
              <div className='account-box-top'>
                <div>All Time</div>
                <div>TNS Balance</div>
              </div>
              <div className='account-box-bot'>
                {TransactionReport ? tnsProfit : 0}
              </div>
            </div>
            <div
              className='account-box d-flex justify-content-between'
              style={{
                background: `linear-gradient(275.37deg, #FF8100 22.91%, #FFA344 76.79%)`,
              }}
            >
              <div className='d-flex flex-column'>
                <div className='account-box-top'>Want to withdraw</div>
                <div>
                  <b>TNS Profit?</b>
                </div>
                <div
                  className='account-witdraw-but'
                  onClick={() =>
                    bankDetails?.id
                      ? hideWithdrawModal(true)
                      : hideAddAccountModal(true)
                  }
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  Withdraw
                </div>
              </div>
              <img
                style={{
                  cursor: 'pointer',
                }}
                src={Settings}
                alt=''
                className='account-sett-img'
                onClick={() =>
                  bankDetails?.id ? hideModal(true) : hideAddAccountModal(true)
                }
              />
            </div>
          </div>

          <Table
            columns={[
              { title: 'Amount (N)', field: 'amount' },
              { title: 'Date', field: 'createdAt' },
              { title: 'Recepient', field: 'recepient' },
            ]}
            data={withdrawals.map((item) => ({
              amount: item.amount,
              createdAt: FormatDate(item.createdAt),
              recepient: item.bank,
            }))}
            title=''
          />
        </div>
        {withdrawModal ? (
          <WithdrawMoney
            hideModal={hideWithdrawModal}
            showModal={withdrawModal}
            bankDetails={bankDetails}
            report={TransactionReport}
            data={data}
            setData={setData}
            handleWithdrawal={handleWithdrawal}
          />
        ) : (
          ''
        )}
        {showModal ? (
          <UpdateAccount
            hideModal={hideModal}
            showModal={showModal}
            banks={banks}
            setData={setData}
            getBankAccountDetails={getBankAccountDetails}
            handleEditAccount={handleEditAccount}
            data={data}
            bankDetails={bankDetails}
          />
        ) : (
          ''
        )}
        {showAddAccountModal ? (
          <AddAccount
            hideModal={hideAddAccountModal}
            showModal={showAddAccountModal}
            banks={banks}
            getBankAccountDetails={getBankAccountDetails}
            data={data}
            setData={setData}
            handleAddAccount={handleAddAccount}
          />
        ) : (
          ''
        )}
      </Layout>
    </div>
  );
};

export default Index;
