import React, { Component } from 'react';

export default function ReportCards(props) {
  const {
    // payout,
    tnsProfit,
    totalUserCurrentWalletBalance,
    totalVendorCurrentWalletBalance,
    withdrawalAmount,
    totalAmountMade,
    // fundWalletTransactions,
  } = props.stats;
  return (
    <div>
      <div className='grp-overview'>
        <div className='grp-overview1'>
          <h1>
            {' '}
            <i className='fa fa-users' aria-hidden='true'></i>Total Amount Made
          </h1>
          <p>{totalAmountMade}</p>
        </div>

        <div className='grp-overview1'>
          <h1>
            {' '}
            <i className='fa fa-users' aria-hidden='true'></i>Available Balance
            <br />
            (for withdrawal)
          </h1>
          <p>
            {Number(tnsProfit) -
              Number(withdrawalAmount) +
              Number(totalVendorCurrentWalletBalance)}
          </p>
        </div>

        <div className='grp-overview1'>
          <h1>
            {' '}
            <i className='fa fa-calendar' aria-hidden='true'></i> TNS Balance
          </h1>
          <p>{`${(Number(tnsProfit) - Number(withdrawalAmount)).toFixed(
            2
          )}`}</p>
        </div>

        <div className='grp-overview1'>
          <h1>
            {' '}
            <i className='fa fa-calendar' aria-hidden='true'></i> Vendors
            Balance
          </h1>
          <p>{totalVendorCurrentWalletBalance}</p>
        </div>

        <div className='grp-overview1'>
          <h1>
            {' '}
            <i className='fa fa-calendar' aria-hidden='true'></i> Wallet Balance
          </h1>
          <p>{+totalUserCurrentWalletBalance}</p>
        </div>
      </div>
    </div>
  );
}
